var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"assets_description",attrs:{"type":"text","label":"Opis składnika majątku","placeholder":"np. samochód Skoda Fabia","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    else if (v.length > 127)
                        { return 'Maksymalna długość nazwy przedmiotu to 127 znaków'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.description),callback:function ($$v) {_vm.$set(_vm.local_data, "description", $$v)},expression:"local_data.description"}})],1),_c('div',{staticClass:"mb-4"},[_c('WkTextField',{ref:"assets_value",attrs:{"type":"text","label":"Szacunkowa wartość","placeholder":"np. 5 000 zł","rules":[
                function (v) {
                    if (!v || v.length == 0) { return 'To pole jest wymagane'; }
                    if (v.length > 31) { return 'Maksymalna długość kwoty to 31 znaków'; }
                    if (!this$1.$store.state.REGEX_IS_MONEY.test(v))
                        { return 'Proszę podać prawidłową kwotę'; }
                    return true;
                }
            ],"show-asterisk":""},model:{value:(_vm.local_data.value),callback:function ($$v) {_vm.$set(_vm.local_data, "value", $$v)},expression:"local_data.value"}})],1),_c('h4',{staticClass:"property-components__title"},[_vm._v("Miejsce przechowywania")]),(!_vm.edit_mode)?_c('WkCheckbox',{staticClass:"mt-2",on:{"change":_vm.onCopyAddressChange},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Składnik majątku przechowywany w miejscu zamieszkania ")]},proxy:true}],null,false,3060115729),model:{value:(_vm.copy_debtor_address),callback:function ($$v) {_vm.copy_debtor_address=$$v},expression:"copy_debtor_address"}}):_vm._e(),(!_vm.copy_debtor_address)?_c('div',{staticClass:"property-components__storage-location-wrapper mb-6",class:{
            ' mt-2': !_vm.edit_mode
        }},[_c('GeneralAddressFormElement',{ref:"address_form",model:{value:(_vm.local_data.address),callback:function ($$v) {_vm.$set(_vm.local_data, "address", $$v)},expression:"local_data.address"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }