//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PpDataField from "@/components/PpDataField.vue";
import PropertyComponentsForm from "@/components/PropertyComponentsForm.vue";
import PpComboDateInput from "../components/PpComboDateInput.vue";

export default {
    components: {
        PpDataField,
        PropertyComponentsForm,
        PpComboDateInput
    },

    data() {
        return {
            assets: [],
            assets_loading: false,
            assets_loaded: false,
            assets_current_page: 1,
            assets_number_of_pages: 1,

            create_dialog: false,
            create_loading: false,

            confirm_dialog: false,

            update_loading: false,

            delete_loading: false
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        openModal() {
            this.create_dialog = true;
        },
        closeModal() {
            this.create_dialog = false;
        },

        async addAsset() {
            if (this.create_loading === true) return;

            if (this.$refs.cfe.validate() !== true) return;

            this.create_loading = true;
            try {
                await this.$axios.$post(`/property-components`, {
                    ...this.$refs.cfe.saveData(),
                    application: this.app._id
                });
                this.$message({
                    type: "success",
                    title: "Składnik majątku został dodany",
                    msg: "Znajdziesz go u dołu listy"
                });

                this.closeModal();
            } catch (error) {
                console.error(error);
            }
            this.create_loading = false;
        },

        async updateAsset(index) {
            if (this.update_loading === true) return;

            if (this.$refs["adfe_" + index][0].validate() !== true) return;

            this.update_loading = true;

            try {
                const data = this.$refs["adfe_" + index][0].saveData();
                this.$axios.$put(`/property-components/${data._id}`, data);
                this.$refs["df_" + index][0].closeEditModal();
            } catch (error) {
                console.error(error);
            }

            this.update_loading = false;
        },

        deleteAsset(id) {
            if (this.delete_loading === true) return;

            this.delete_loading = true;

            try {
                this.$axios.$delete(`/property-components/${id}`);
            } catch (error) {
                console.error(error);
            }

            this.delete_loading = false;
        },

        async sendAssetsData() {
            if (this.sending === true) return;

            if (this.assets.length < 1) {
                return this.$message({
                    type: "error",
                    title: "Niekompletne dane",
                    msg: "Dodaj co najmniej jeden składnik majątku"
                });
            }

            this.sending = true;

            try {
                await this.$axios.$post(
                    `/applications/${this.$store.getters["application/getApplication"]._id}/confirm-property-components`
                );

                this.$message({
                    type: "success",
                    title: "Sukces",
                    msg: "Składniki majątku zostały zatwierdzone"
                });

                this.$router.push({
                    name: "recurring-expenses"
                });

                this.closeConfirmDataModal();
            } catch (error) {
                console.error(error);
            }
            this.sending = false;
        },

        getAssetInfo(asset) {
            return `${asset.value} • ${this.$store.getters["displayGeneralAddress"](
                asset.address
            )}`;
        },

        handleNewPropertyComponent(pc) {
            this.assets.push(pc);
        },

        handleUpdatePropertyComponent(pc) {
            const ix = this.assets.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            const item_copy = { ...this.assets[ix] };

            this.assets.splice(ix, 1, {
                ...item_copy,
                ...pc
            });
        },

        handleDeletePropertyComponent(pc) {
            const ix = this.assets.findIndex(asset => asset._id === pc._id);

            if (ix === -1) return;

            this.assets.splice(ix, 1);
        },

        onIntersectionObserverIntersect() {
            this.assets_current_page++;
            this.fetchData();
        },

        async fetchData() {
            if (this.assets_loading === true) return;

            this.assets_loading = true;

            try {
                const data = await this.$axios.$get(
                    `/property-components/?application=${this.app._id}&page=${this.assets_current_page}&items_per_page=32`
                );
                this.assets = this.assets.concat(data.property_components);
                this.assets_current_page = data.pagination.current_page;
                this.assets_number_of_pages = data.pagination.number_of_pages;
                this.assets_loaded = true;
            } catch (error) {
                console.error(error);
            }
            this.assets_loading = false;
        },

        openConfirmModal() {
            this.confirm_dialog = true;
        },

        closeConfirmDataModal() {
            this.confirm_dialog = false;
        }
    },

    mounted() {
        if (!this.$store.state.initial_layout_set) return;
        this.fetchData();
        this.$io.on("PropertyComponent.created", this.handleNewPropertyComponent);
        this.$io.on("PropertyComponent.updated", this.handleUpdatePropertyComponent);
        this.$io.on("PropertyComponent.deleted", this.handleDeletePropertyComponent);
    },

    beforeDestroy() {
        this.$io.off("PropertyComponent.created", this.handleNewPropertyComponent);
        this.$io.off("PropertyComponent.updated", this.handleUpdatePropertyComponent);
        this.$io.off("PropertyComponent.deleted", this.handleDeletePropertyComponent);
    },

    metaInfo: {
        title: "Składniki majątku"
    }
};
