//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GeneralAddressFormElement from "./GeneralAddressFormElement.vue";

export default {
    components: { GeneralAddressFormElement },

    props: {
        label: {
            type: String
        },
        value: {
            type: Object
        }
    },

    data() {
        return {
            local_data: {
                description: "",
                value: "",
                address: null
            },
            copy_debtor_address: true,
            edit_mode: false,
            country_select_search: ""
        };
    },

    computed: {
        app() {
            return this.$store.getters["application/getApplication"];
        }
    },

    methods: {
        onCopyAddressChange() {
            if (this.copy_debtor_address) {
                if (!this.app) return;
                this.local_data = {
                    ...this.local_data,
                    address: this.app.debtor_personal_data.address
                };
            }
        },
        saveData() {
            return this.local_data;
        },
        handleValue() {
            if (this.value) {
                this.local_data = { ...this.value };
                this.copy_debtor_address = false;
                this.edit_mode = true;
            } else {
                if (this.app) {
                    this.local_data = {
                        ...this.local_data,
                        address: this.app.debtor_personal_data.address
                    };
                }
                this.edit_mode = false;
            }
        },
        validate() {
            const validation_inputs = [
                this.$refs.assets_value.validate(),
                this.$refs.assets_description.validate()
            ];
            if (!this.copy_debtor_address) {
                validation_inputs.push(this.$refs.address_form.validate());
            }
            if (validation_inputs.indexOf(false) !== -1) {
                return false;
            }
            return true;
        }
    },

    watch: {
        value() {
            this.handleValue();
        }
    },

    mounted() {
        this.handleValue();
    }
};
